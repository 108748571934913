import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _fdf94fce = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _07e4281e = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _ed63ce74 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _ed5966ae = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _c22bb8d4 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _83f08e8c = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _65f37649 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _9b037f96 = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _63da3df4 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _7a7e77ee = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _60e92f39 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _827dd914 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _537bcfa8 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _1e30ec0c = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _5d0376d5 = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _74d8411f = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _01da8fd6 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _33715ddf = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _7c75f6b0 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _fdf94fce,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _07e4281e,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _ed63ce74,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _ed5966ae,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _c22bb8d4,
    name: "receipt"
  }, {
    path: "/search",
    component: _83f08e8c,
    name: "search"
  }, {
    path: "/search-booking",
    component: _65f37649,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _9b037f96,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _63da3df4,
    name: "terms-and-conditions"
  }, {
    path: "/unsupported-browser",
    component: _7a7e77ee,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _60e92f39,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _827dd914,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _537bcfa8,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _1e30ec0c,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _5d0376d5,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _74d8411f,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _01da8fd6,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _33715ddf,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _7c75f6b0,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
